// Generated by Framer (1d71865)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["P6MhXh9qF"];

const serializationHash = "framer-XV8N6"

const variantClassNames = {P6MhXh9qF: "framer-v-1y1xrgu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {duration: 0, type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "P6MhXh9qF", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1y1xrgu", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"P6MhXh9qF"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1a7kg26"} data-framer-name={"play_1_"} fill={"rgb(255, 255, 255)"} intrinsicHeight={512} intrinsicWidth={512} layoutDependency={layoutDependency} layoutId={"HiC0aTU4D"} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"512\" height=\"512\"><path d=\"M20.492 7.969 10.954.975A5 5 0 0 0 3 5.005V19a4.994 4.994 0 0 0 7.954 4.03l9.538-6.994a5 5 0 0 0 0-8.062Z\"/></svg>"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-XV8N6.framer-1i5pj5n, .framer-XV8N6 .framer-1i5pj5n { display: block; }", ".framer-XV8N6.framer-1y1xrgu { height: 40px; overflow: visible; position: relative; width: 40px; }", ".framer-XV8N6 .framer-1a7kg26 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 40px); left: 0px; position: absolute; right: 0px; top: 0px; z-index: 1; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerRLQ67Sz85: React.ComponentType<Props> = withCSS(Component, css, "framer-XV8N6") as typeof Component;
export default FramerRLQ67Sz85;

FramerRLQ67Sz85.displayName = "Play";

FramerRLQ67Sz85.defaultProps = {height: 40, width: 40};

addFonts(FramerRLQ67Sz85, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})